import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContractorsGLMeta } from "../data/seo-meta"
import CallToAction from "../components/call-to-action"

import contractorImg from "../images/cgl/contractor.svg"
import hotAirBalloon from "../images/common/hot_air_ballon.svg"
import quoteIcon from "../images/common/quote.svg"

import touchIcon from "../images/common/touch.svg"
import diamondIcon from "../images/common/diamond.svg"
import checkCircleIcon from "../images/common/check_circle.svg"

import { platformLink } from "../data/external-links"

const smallBusinessChecklist = [
  '60+ class codes',
  'Premiums up to $10k',
  'Pay monthly or annually using a credit card.',
  'Quote in 30 seconds & issuing in under 5 min.'
]

const midMarketChecklist = [
  '70+ class codes',
  'Premiums averaging $50k',
  'Project Specific Policies (up to 24 months)',
  'Owner’s Interest Policies',
  <><b>Excess</b> – Up to $5M limits available</>
]

const productOferring = [
  {
    title: 'Target Exposures',
    iconSrc: touchIcon,
    body: [
      'Artisan/Trade Contractors',
      'Small General Contractors',
      'Remodeling Contractors',
      'Available in all states except, Kentucky, Louisiana, and New York'
    ]
  },
  {
    title: 'Target Classes',
    iconSrc: diamondIcon,
    body: [
      'Carpentry',
      'Painting, Plumbing',
      'Drywall',
      'Subcontractors',
      'HVAC',
      'Janitorial, Landscaping',
      'Electrical',
      'Excavation',
      'Grading of Land',
      'General Contractors',
      'And many more'
    ]
  },
  {
    title: 'Highlights',
    iconSrc: checkCircleIcon,
    body: [
      <>
        Non-admitted Paper: <span className="font-semibold">A.M. Best A++ (Superior) Rating</span>
      </>,
      'Inland Marine Coverage',
      'New Businesses Accepted',
      <>
        Flexible Limits:
        <ul className="list-['-'] list-inside">
          <li> up to $1M per occurrence</li>
          <li> $2M General and Products aggregate</li>
        </ul>
      </>,
      'First Dollar Coverage',
      <>
        Available coverages enhancements <i>(Blanket Additional Insured, including Completed Operations, Per Project Aggregate, Waiver of Subrogation, and Primary Non-Contributory Coverage)</i>
      </>
    ]
  },
]

const cglQuotes = [
  {
    quote: "You have the best and most thorough quote so far for the coverage my customer requires.",
    author: "Robin M.",
    location: "TN",
  },
  {
    quote: "Everything was nice and smooth, and fast! Loved it!",
    author: "Gina.",
    location: "TX",
  },
  {
    quote: "Excellent service provided, transparent insurance process, user-friendly and hassle-free online platform.",
    author: "Christina B.",
    location: "TX",
  }
]

const ContractorsGLPage = () => {
  return (
    <Layout>
      <SEO
        description={ContractorsGLMeta.description}
        path={ContractorsGLMeta.path}
        title={ContractorsGLMeta.title}
      />
      <div className="container py-20 md:py-24">
        <div className="grid md:grid-cols-2 gap-20 mb-32">
          <div className="md:pr-24">
            <h1 className="h1 mb-8">Contractors General Liability</h1>
            <p className="p1">
              Our Small Business and Mid-Market Contractors
              General Liability coverage is designed for artisans,
              general contractors and remodelers across 48 states.
              <br />
              <br />
              Generate quotes quickly, streamline your service, and enhance client satisfaction.
            </p>
            <br />
            <CallToAction label="Access Slice" to={platformLink} external />
          </div>
          <img src={contractorImg} alt="Contractor working on a house" />
        </div>

        <h2 className="h2 mb-8">Our Small Business and Mid-Market products offer:</h2>
        <div className="flex flex-col md:flex-row gap-10 mb-32">
          {productOferring.map((group, index) => (
              <div
                key={group.title}
                className={`px-8 py-10 rounded-2xl drop-shadow shrink-[1] basis-[0] ${productOferring.length - 1 === index ? "grow-[2]" : "grow-[1]"}`}
              >
                <img className="w-16 mb-8" src={group.iconSrc} alt="Plus icon" />
                <h4 className="h4 mb-8">{group.title}</h4>
                <ul className="p2 list-disc px-[18px]">
                  {group.body.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              </div>
            )
          )}
        </div>

        <div className="flex flex-col md:flex-row gap-8 mb-32">
          <div className="basis-full flex flex-col justify-between md:gap-10">
            <div>
              <h2 className="h2 mb-8">Quote & issue <b>Small Business</b> insurance with ease.</h2>
              <ul className="checklist">
                {smallBusinessChecklist.map((item, index) => (
                  <li key={index} className="p1">{item}</li>
                ))}
              </ul>
            </div>
            <div>
              <CallToAction
                label="Download Small Business Appetite Guide"
                to="https://info.sliceinsurance.com/contractors-general-liability-e-and-s-appetite-guide-download"
                external
              />
            </div>
          </div>
          <div className="basis-full flex flex-col justify-between md:gap-10">
            <div>
              <h2 className="h2 mb-8">Comprehensive <b>Mid-Market</b> coverage made simple.</h2>
              <ul className="checklist">
                {midMarketChecklist.map((item, index) => (
                  <li key={index} className="p1">{item}</li>
                ))}
              </ul>
            </div>
            <div>
              <CallToAction
                label="Download Mid-Market Appetite Guide"
                to="https://info.sliceinsurance.com/contractors-general-liability-m-appetite-guide-download-mid-market"
                external
              />
            </div>
          </div>
        </div>

        <div className="grid gap-8 md:grid-cols-3 mb-40">
          {cglQuotes.map((quoteObj) => (
            <div
              className="-mx-8 px-10 py-16 bg-[#f8f8f8] md:mx-0 rounded-2xl drop-shadow flex flex-col justify-between h-full"
              key={quoteObj.author}
            >
              <div className="mb-4">
                <img className="mb-8" src={quoteIcon} alt="Quote icon" />
                <p className="text-[2.4rem] leading-[3.6rem] italic">{quoteObj.quote}</p>
              </div>
              <p className="text-[1.6rem] leading-[3.2rem]">
                  {quoteObj.author}<br />
                  {quoteObj.location}
                </p>
            </div>
          ))}
        </div>

        <div className="md:text-center mt-48">
          <img className="mx-auto" src={hotAirBalloon} alt="Hot air balloon" />
          <h2 className="h1 my-8">Quote with Slice today.</h2>
          <p className="p1">
            Create your account or log in to access Slice.
            <br />
            <br />
            <CallToAction label="Access Slice" to={platformLink} external />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ContractorsGLPage
